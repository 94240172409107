const messages = {
  aboutMe: 'A propos',
  competences: 'Compétences',
  contact: 'Contactez-moi !',
  education: 'Formation',
  email: 'E-mail',
  experiences: 'Expériences',
  firstName: 'Prénom',
  footerText:
    'Ce site est garanti 100% sans cookies ! Développé par Sébastien CAYET (lui-même) avec Angular 19.',
  from: 'De',
  greatings: 'Bonjour !',
  greatingsDescription:
    'Je suis Sébastien, Lead Developpeur Web',
  greatingsOccupation: 'Actuellement Techlead Angular pour Klanik, à Lyon.',
  home: "Page d'accueil",
  knowledge: 'Connaissances',
  languages: 'Langues',
  languagesText:
    "Je pense que la connaissance de différentes langues est importante quand on veut assimiler différentes cultures. Le français est ma langue maternelle, mais j'utilise surtout l'anglais pour travailler. J'ai appris l'espagnol durant mes études. Aujourd'hui, j'apprends le japonais.",
  lastName: 'Nom',
  message: 'Message',
  metaDescription: 'Explorez le site personnel de Sébastien Cayet, exposant ses domaines d\'expertise pour délivrer des applications complexes mais attrayantes pour l\'utilisateur : Développement Web, UX Design, Management Agile, Ecriture de spécifications et plus !',
  metaTitle: 'Sébastien Cayet - Lead Développeur Frontend',
  myTastes: 'Mes goûts',
  myStyle: 'Mon style',
  mailFormText: "Remplissez le formulaire ci-dessous pour m'envoyer un mail.",
  mailFormMandatoryErrorMessage: 'Ce champ est obligatoire.',
  mailFormEmailErrorMessage: 'Entrez un adresse mail valide.',
  mailFormErrorMessage:
    "Ce formulaire n'a pas être envoyé parce qu'au moins un des champs est invalide.",
  personalInformations: 'Informations Personnelles',
  portefolio: 'Portefolio',
  programming: 'Languages de programmation',
  resumeTitle: 'Mon Curiculum',
  scrollThrough: 'Souhaitez-vous en savoir d\'avantage ?   ~   Visitez donc !',
  seeExperiences: 'Voir mes expériences',
  seeCompetences: 'Voir mes compétences',
  seeResume: 'Mon CV',
  sendMail: 'Contactez-moi',
  send: 'Envoyer',
  siteDescription: '',
  subject: 'Objet',
  subtitle: 'Bonjour ! Je suis développeur web !',
  title: 'Sébastien CAYET',
  theory: 'Théorie',
  to: 'à',
  tooling: 'Outils',
  visit: 'Visiter',
};

export default messages;
